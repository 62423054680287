<template>
  <div :class="`slide-down-notification is-flex is-justify-content-center ${show ? 'visible' : ''}`">
    <div class="wrapper">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  }
}
</script>
<style lang="scss" scoped>
.slide-down-notification  {
  position: fixed;
  z-index: 111;
  width: 100vw;
  top: -200px;
  left: 0;
  transition: all 0.5s;
  &.visible {
    top: 13px;
  }
  .wrapper {
    min-width: 168px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
  }
}
</style>
