<template>
  <div id="interac-wrap">
    <NavBack/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding pt-unset-embedded-in-ios">
      <div class="page-title has-text-weight-semibold has-text-centered mb-3">Add funds via Interac</div>
      <div class="main has-background-white">
        <div class="page-subtitle has-text-centered px-2" id="anchor">Please use the information below to add funds to your addy wallet.</div>
        <section
          class="interac-info"
          v-for="info in interacInfo"
          :key="info.type"
        >
          <div class="section-title">{{ info.title }}</div>
          <div class="dash-box is-relative is-clickable" @click="copyInfo(info.type, info.content)">
            <div :class="`inner is-flex is-justify-content-space-between is-align-items-center ${copyState[info.type] ? 'copied' : ''}`">
              <span>{{ info.content }}</span>
              <img src="@assets/icons/clipboard.svg" alt="clipboard">
            </div>
            <img src="@assets/images/referral/dash-outline.png" alt="dash" class="dash">
          </div>
        </section>
        <section class="interac-info">
          <div class="section-title">3. Amount to transfer</div>
          <InputInTwoDecimals
            scrollAnchorId="anchor"
            pageId="interac-wrap"
            @onInput="onInput"
          ></InputInTwoDecimals>
        </section>
        <div class="prompt has-text-centered">You can send a min of $5 per deposit.</div>
      </div>
      <BottomButtons
        nextButtonText="Finish"
        backButtonText="Back"
        :showSteps="false"
        :nextDisabled="amount < 5"
        :loading="finishButtonIsLoading"
        @goNext="trigerInterac"
        @goBack="goBack"
      ></BottomButtons>
    </div>
    <Notification :show="popup.email || popup.message">
      <div class="notification-popup is-flex is-justify-content-center is-align-items-center">
        <span class="has-text-blue has-text-weight-bold">{{ popup.email ? 'Email' : 'Message' }} copied</span>
        <img src="@assets/icons/clip.svg" alt="clip">
      </div>
    </Notification>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import Notification from '@components/modal/slide-down-notification.vue'
import InputInTwoDecimals from '@components/input/currency-input.vue'
import { addFundsToWallet } from '@api/wallet'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  components: {
    NavBack,
    MenuAndLogo,
    BottomButtons,
    Notification,
    InputInTwoDecimals,
  },
  data() {
    return {
      copyState: {
        email: false,
        message: false,
      },
      popup: {
        email: false,
        message: false,
      },
      timer: undefined,
      amount: 0,
      finishButtonIsLoading: false,
    }
  },
  computed: {
    interacInfo() {
      const investor = JSON.parse(localStorage.getItem('investor'))
      return [
        {
          title: '1. Send your Interac e-Transfer to:',
          type: 'email',
          content: 'support@addyinvest.com'
        },
        {
          title: '2. Include this code in the message field of your e-Transfer, or funds might be lost:',
          type: 'message',
          content: `Member ID: ${investor.hashid}`
        },
      ]
    },
  },
  methods: {
    trigerInterac() {
      if (this.amount < 5) return
      this.finishButtonIsLoading = true
      addFundsToWallet(this.amount, null, 'interac').then((result) => {
        if (!result.success) return
        if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
        this.$router.push('/wallet')
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    copyInfo(type, content) {
      this.copyState[type] = true
      this.$copyText(content).then(() => {
        this.togglePopupMessage(type)
      })
    },
    togglePopupMessage(type) {
      const state = this.popup[type]
      if (state) return

      clearTimeout(this.timer)
      this.popup[type === 'email' ? 'message' : 'email'] = false
      this.popup[type] = true
      this.timer = setTimeout(() => {
        this.popup[type] = false
      }, 3000)
    },
    onInput(value) {
      this.amount = value
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

#interac-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  padding-bottom: 30px;
  .has-padding {
    padding: 18px 25px 0;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      font-size: 16px;
      border-radius: 12px;
      box-shadow: 0px 30px 50px rgba(215, 145, 51, 0.11);
      margin-bottom: 30px;
      padding: 22px 14px;
      .page-subtitle {
        line-height: 20px;
        padding-bottom: 28px;
      }
      .interac-info {
        margin-bottom: 24px;
        .section-title {
          font-size: 16px;
          padding-left: 10px;
          margin-bottom: 8px;
        }
        .dash-box {
          height: 52px;
          .dash {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
          .inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-left: 16px;
            padding-right: 20px;
            border-radius: 12px;
            &.copied {
              background-color: #F0EEF8;
              background-origin: padding-box;
            }
            img {
              width: 16px;
            }
          }
        }
      }
      .prompt {
        color: #9185AE;
        font-size: 16px;
        line-height: 19px;
        padding-top: 16px;
      }
    }
  }
  .notification-popup {
    font-size: 16px;
    width: 100%;
    height: 100%;
    img {
      margin-left: 10px;
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  #interac-wrap {
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
